<template>
  <!-- 侧边栏 -->
  <v-container fluid class="pa-0">
    <v-card elevation="0" tile >
      <!--标题 -->
      <v-card-title class="background-color">
        <v-img src="../../public/logo.png" max-height="45" max-width="45"></v-img>
        <span class="white--text font-wieght-bold">{{$t('lang.login.platform')}}</span>
      </v-card-title>
      <!-- 菜单 -->
      <v-card-text style="height: calc(100vh - 77px)" class="overflow-y-auto">
        <v-list>
          <v-list-item-group color="primary">
            <template v-for="(menuItem,i) in menuList">
              <v-divider :key="i+1" v-if="i == menuList.length-1" class="mt-8"></v-divider>
              <v-list-item :key="i" @click="toPath(menuItem.path)" v-if="!menuItem.subList && menuItem.show">
                <v-list-item-icon>
                  <v-icon v-text="menuItem.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="menuItem.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group :value="false" :key="i" :prepend-icon="menuItem.icon" no-action v-else-if="menuItem.subList && menuItem.show">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ menuItem.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-for="(subMenuItem, i) in menuItem.subList">
                  <v-list-item
                  :key="i"
                  @click="toPath(subMenuItem.path)"
                  v-if="subMenuItem.show"                 
                >
                    <v-list-item-icon>
                      <v-icon v-text="subMenuItem.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="subMenuItem.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      menuList: [
        { text: this.$t('lang.home.sideBar.frontPage'), icon: "mdi-home", path: "/main", show: this.$root.authority != 'ROLE_FINANCIAL' },
        { text: this.$t('lang.home.sideBar.stationManagement'), icon: "mdi-cash-marker", path: "/station", show: this.$root.authority != 'ROLE_FINANCIAL' },
        { text: this.$t('lang.home.sideBar.projectManagement'), icon: "mdi-view-list", path: "/project", show: this.$root.authority != 'ROLE_FINANCIAL' },
        {
          text: this.$t('lang.home.sideBar.packageManagement'),
          icon: "mdi-package",
          show: true,
          subList: [
            {
              text: this.$t('lang.home.sideBar.quickBuy'),
              icon: "mdi-cash-100",
              path: "/buy",
              show: this.$root.authority == 'ROLE_USER'
            },
            // {
            //   text: this.$t('lang.home.sideBar.pricingDisplay'),
            //   icon: "mdi-cash-100",
            //   path: "/priceDisplay",
            // },
            {
              text: this.$t('lang.home.sideBar.purchaseHistory'),
              icon: "mdi-credit-card-outline",
              path: "/purchaseHistory",
              show: true
            },
          ],
        },
        // { text: this.$t('lang.home.sideBar.contractManagement'), icon: "mdi-file-document-edit", path: "/contract", show: true },
        { text: this.$t('lang.home.sideBar.userManagement'), 
          icon: "mdi-account-group", 
          show: this.$root.authority == 'ROLE_ADMIN' || this.$root.authority == 'ROLE_USER_ADMIN',
          subList: [
            {
              text: this.$t('lang.user.userInfo'),
              icon: 'mdi-account-details',
              path: '/userList',
              show: true
            },
            {
              text: this.$t('lang.user.userApplication'),
              icon: 'mdi-account-check',
              path: '/userApply',
              show: true
            } 
          ],
        },
        {
          text: this.$t('lang.home.sideBar.dataDisplay'),
          icon: "mdi-data-matrix",
          show: this.$root.authority != 'ROLE_FINANCIAL',
          subList: [
            {
              text: this.$t('lang.home.sideBar.visualization'),
              icon: 'mdi-chart-scatter-plot',
              path: '/visualization',
              show: true
            },
            {
              text: this.$t('lang.home.sideBar.history'),
              icon: 'mdi-poll',
              path: '/history',
              show: true
            } 
          ],
        },
        { text: this.$t('lang.home.sideBar.chat'), icon: "mdi-chat-processing", path: "/chat", show: this.$root.authority == 'ROLE_USER' },
        //{ text: this.$t('lang.home.sideBar.systemNotification'), icon: "mdi-bullhorn", path: "/notice", show: true },
        { text: this.$t('lang.home.sideBar.setUp'), icon: "mdi-cog", path: "/setting", show: true },
      ],
      //selectedMenuItem: 1
    };
  },
  computed: {
  },
  mounted() {
    console.log(this.$root.authority, this.$root.certType)
  },
  methods: {
    toPath(path) {
      this.$router.push(path);
    },
  },
  watch: {},
};
</script>
<style scoped>
.background-color {
  background: rgb(77, 139, 255);
}
</style>