import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import leafletCss from "leaflet/dist/leaflet.css";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import VueI18n from 'vue-i18n';
import * as axiosCfg from "./config/axios";
import DatetimePicker from 'vuetify-datetime-picker'

Vue.config.productionTip = false

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})

Vue.use(VueI18n)

Vue.use(DatetimePicker)

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',    // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale 
  messages: {
    'zh-CN': require('./lang/zh'),   // 中文语言包
    'en-US': require('./lang/en')    // 英文语言包
  }
})

new Vue({
  router,
  vuetify,
  leafletCss,
  i18n,
  axiosCfg,
  render: h => h(App)
}).$mount('#app')
