export const lang = {
    register: {
        title: 'Register an account',
        customerTypeLabel: 'Type',
        naturalPerson: 'Natural person',
        company: 'Company',
        businessLicense: 'Business license',
        usernameLabel: 'Username',
        passwordLabel: 'Password',
        confirmPwdLabel: 'Re-enter',
        phoneNumberLabel: 'Phone number',
        verificationCodeLabel: 'Verification code',
        verificationCodeBtn: 'Get code',
        resendverificationCode: 'Resend',
        registerBtn: 'Register',
        toLoginBtn: 'Already have an account? go to login page',
        weiHaiWuZhou: 'Weihai Wuzhou',
        usernameRules: {
            required: 'Username is required',
            specialCharacterRestriction: 'Username cannot contain special characters',
            minimumCharacterLimit: 'Username cannot be less than 8 characters',
            maximumCharacterLimit: 'Username cannot exceed 20 characters'
        },
        passwordRules: {
            required: 'Password is required',
            minimumCharacterLimit: 'Password cannot be less than 16 characters',
            maximumCharacterLimit: 'Password cannot exceed 25 characters',
            specialCharacterRestriction: 'Password must contain both letters and numbers',   
        },
        confirmPwdRules: {
            required: 'Confirm password is required',
            minimumCharacterLimit: 'Confirm password cannot be less than 16 characters',
            maximumCharacterLimit: 'Confirm password cannot exceed 25 characters',
            specialCharacterRestriction: 'Confirm password must contain both letters and numbers', 
            passwordConsistency: 'The two entered passwords do not match'  
        },
        phoneNumberRules: {
            required: 'Mobile number is required',
            formatValidation: 'Malformed phone number',
        },
        verificationCodeRules: {
            required: 'Verification code is required'
        },
        sendSuccess: 'Sent successfully',
        sendFail: 'Failed to send'
    },
    login: {
        platform: 'GNSS Positioning Enhancement Service Platform',
        title: 'Login please',
        loginBtn: 'Login',
        toRegisterBtn: "Don't have an account? Sign up",
        forgotPassword: 'Forgot password',
        resetPassword: {
            title: 'Reset Password',
            phoneNumberVerification: 'Phone number verification',
            nextStep: 'Next step',
            backToLogin: 'Back to login page',
            enterPassword: 'Enter password',
            finishBtn: 'Finish',
            resetSuccess: 'Reset successfully',
            resetFail: 'Reset failed'
        }
    },
    home: {
        sideBar: {
           frontPage: 'Front page',
           stationManagement: 'Station board',
           projectManagement: 'Project board',
           packageManagement: 'Package board',
           pricingDisplay: 'Pricing display',
           purchaseHistory: 'Purchase history',
           contractManagement: 'Contract board',
           userManagement: 'User board',
           visualization: 'Visualization',
           dataDisplay: 'Data display',
           history: 'Historical data',
           chat: 'Chat',
           systemNotification: 'System notification',
           setUp: 'Set up',
           quickBuy: 'Quick buy'
        },
        description: 'A platform designed to provide more precise positioning for enterprises and users',
        settingBtn: 'Set up',
        signOutBtn: 'Sign out',
        count: {
            totalUsers: 'Total number of users',
            totalProjects: 'Total number of projects',
            totalStations: 'Total number of stations',
            onlineStations: 'Number of online stations',
            totalContracts: 'Total number of contracts',
            signedContracts: 'Number of contracts signed'
        },
        stationLocation: 'Location display for stations',  
    },
    station: {
        stationNameRules: {
            required: 'Station name is required',
            specialCharacterRestriction: 'Station name cannot contain special characters',
            maximumCharacterLimit: 'Station name cannot exceed 20 characters'
        },
        latitudeRules: {
            required: 'Latitude is required',
            specialCharacterRestriction: 'Latitude cannot contain special characters',
        },
        longitudeRules: {
            required: 'Longitude is required',
            specialCharacterRestriction: 'Longitude cannot contain special characters',
        },
        heightRules: {
            required: 'Height is required',
            specialCharacterRestriction: 'Height cannot contain special characters',
          },
        xRules: {
            required: 'x coordinate is required',
            specialCharacterRestriction: 'x coordinate cannot contain special characters',
        },
        yRules: {
            required: 'y coordinate is required',
            specialCharacterRestriction: 'y coordinate cannot contain special characters',
        },
        zRules: {
            required: 'z coordinate is required',
            specialCharacterRestriction: 'z coordinate cannot contain special characters',
        },
        newStationBtn: 'New station',
        searchLabel: 'Search',
        stationId: 'Station id',
        stationName: 'Station name',
        stationType: 'Station type',
        latitude: 'Latitude',
        longitude: 'Longitude',
        height: 'Height',
        x: 'x coordinate',
        y: 'y coordinate',
        z: 'z coordinate',
        publicStation: 'Public station',
        status: 'Status',
        connectionKey: 'Connection key',
        creationTime: 'Creation time',
        operation: 'Operation',
        editBtn: 'Edit',
        deleteBtn: 'Delete',
        newStationTitle: 'New station',
        stationEditorTitle: 'Station editor',
        referenceStation: 'Reference station',
        roverStation: 'Rover station',
        setAsPublicStation: 'Set as public station',
        cancelBtn: 'Cancel',
        saveBtn: 'Save',
        online: 'Online',
        offline: 'Offline',
        isPublicStation: 'Yes',
        notPublicStation: 'No',
        runningStatus: 'Running',
        isRunning: 'Yes',
        notRunning: 'No',
        stationIdRules: {
            required: 'Station id is required',
            specialCharacterRestriction: 'Station id cannot contain special characters',
            maximumCharacterLimit: 'Station id cannot exceed 20 characters'
        },
        difference: 'Difference',
        lock: 'Lock',
        noSetRefStation: 'The station is already in the project, no reference station can be set',
        setProject: 'Set project',
        noSetProject: 'The corresponding relationship of the reference station of this station has been locked, and a project cannot be set',
        deleteProject: 'Delete project'
    },
    project: {
        projectNameRules: {
            required: 'Project name is required',
            specialCharacterRestriction: 'Project name cannot contain special characters',
            maximumCharacterLimit: 'Project name cannot exceed 20 characters'
        },
        referenceStationRules: {
            required: 'Reference station is required',
            minCounts: 'The number of reference stations cannot be less than two'
        },
        roverStationRules: {
            required: 'Rover station is required'
        },
        descriptionRules: {
            required: 'Project description is required',
            specialCharacterRestriction: 'Project description cannot contain special characters',
            maximumCharacterLimit: 'Project description cannot exceed 300 characters'
        },
        newProjectBtn: 'New project',
        projectId: 'Project id',
        projectName: 'Project name',
        projectDescription: 'Project description',
        accessKey: 'Access key',
        newProjectTitle: 'New project',
        projectEditorTitle: 'Project editor',
        templateHints: 'Tips: When creating a project according to the template, only one reference station can be selected for differential positioning, and multiple reference stations can be selected for the CORS network. You can also create your own without the template.',
        skipTemplate: 'Skip template',
        diffPositioning: 'Differential positioning',
        corsNet: 'CORS net',
        checkbox: 'Multiple choice',
        radio: 'Single choice',
        nextStep: 'Next step',
        previousStep: 'Previous',
        underProject: 'The rover station is under another project',
        overwritten: 'The correspondence between the rover and the reference station will be overwritten',
        currentRoverList: 'Current rover station list',
        addRover: 'Add a rover',
        resolving: 'resolving',
        resolvingList: 'Resolving List',
        start: 'Start',
        stop: 'Stop'
    },
    package: {
        educationName: 'Education edition',
        educationDescription: 'Provide student identity authentication and enjoy different preferential services...',
        basicName: 'Basic edition',
        basicDescription: 'Provide location enhancement services for governments/enterprises/users',
        freeTrial: 'Free trial',
        minute: 'minutes',
        experienceService: 'Experience unprecedented high precision service',
        payPerView: 'Pay per view',
        enjoyService: 'Enjoy a full range of services',
        experienceAdvantages: 'Continue to experience the advantages of high-precision positioning',
        times: 'times',
        billedMonthly: 'Billed monthly',
        monthlyDiscount: 'Monthly discount',
        month: 'month',
        premiumName: 'Premium edition',
        customizedServices: 'Customized services to meet your various needs',
        casesReference: 'Various cases are available for reference',
        operatorsHoping: 'Operators hope that shared motorcycles/online car-hailing/shared cars have significantly better accuracy than conventional base station-assisted GPS positioning',
        privateCar: 'Private car market',
        farmEquipment: 'Farm Equipment Market',
        hopingOfSMEs: 'SMEs want to test their own location or navigation services at a lower cost',
        NonNavigation: 'Some non-navigation researchers wish to simply engage in the study of navigation and positioning',
        governmentHoping: 'Government departments want high-precision monitoring of bridges and dams',
        havingQustions: 'If you have any questions',
        contact: 'Please contact',
        confirmBtn: 'Confirm',
        priceRules: {
            required: 'Price is required',
            specialCharacterRestriction: 'Price cannot contain special characters',
        },
        freeTry: 'Try this package for free',
        completeStudentId: 'Please go to the settings to complete your student ID information',
        indivual: 'indivual',
        months: 'months',
        numberOfStations: 'The number of configurable stations is ',
        serviceTime: 'The available service time is ',
        dataDuration: 'Data storage duration is ',
        dataFormat: 'The data return format is '
    },
    paymentStepper: {
        package: {
            title: 'Package',
            name: 'Package name',
            type: 'Package type',
            unitPrice: 'Unit price',
            purchaseQuantity: 'Purchase quantity',
            totalAmount: 'Total amount',
        },
        paymentMethod: {
            title: 'Payment method',
            hint: 'Please select mode of payment',
            onlinePayments: 'Online payments',
            bankTransfer: 'Bank transfer',
        },
        payment: 'Payment',
        finishBtn: 'Finish',
        contract: {
            title: 'Contract',
            partyA: 'Party A',
            partyB: 'Party B',
            totalAmount: 'Total amount',
            totalServices: 'Total number of services',
            dataStorageTime: 'Data storage time',
            dataReturnFormat: 'Data return format',
            contractDescription: 'Contract description',
            agreeContract: 'Agree to the content of the above contract'      
        },
        purchaseBtn: 'Purchase'
    },
    quickBuy: {
        buyGuide: 'Buying guide',
        studentDiscount: 'Student status already enjoys the best price',
        stationNum: 'Number of stations',
        serviceTime: 'Service time',
        dataHoldTime: 'Data storage time',
        dataFormat: 'Data return format',
        moreTime: 'More time',
        oneMonth: '1 month',
        oneYear: '1 year',
        threeYears: '3 years',
        fiveYears: '5 years',
        fourYears: '4 years',
        twoYears: '2 years',
        halfYear: 'Half a year',
        threeMonths: '3 months',
        twoMonths: '2 months',
        fifteenDays: '15 days',
        cost: 'Cost',
        calculatingPrice: 'Calculating price...',
        confirmBuy: 'Confirm purchase',
        buyTips: 'Confirm the purchase content and payment amount',
        buyBtn: 'Buy',
        renewalBtn: 'Renewal',
        tips: {
            buyFail: 'Failed purchase',
            buySuccess: 'Successful purchase'
        },
        transfer: {
            hint: 'You have successfully placed an order, please confirm the account information of the other party before transferring',
            bank: 'Bank',
            bankName: 'Bank of China',
            account: 'Account',
            companyName: 'Company name',
            wuzhou: 'Weihai Wuzhou Satellite Navigation Technology Co., Ltd.',
            phone: 'Contact number',
        },
        buyFailTip: {
            reason: 'Reason for failure',
            unpaid: 'There are unpaid orders',
            netError: 'Network error'
        }

    },
    contractWizard: {
        stationCountsRules: {
            required: 'The number of stations is required',
            specialCharacterRestriction: 'The number of stations cannot contain special characters',
        },
        serviceTimesRules: {
            required: 'Total number of services is required',
            specialCharacterRestriction: 'Total number of services cannot contain special characters',
        },
        serviceTimeRules: {
            required: 'Service time is required'
        },
        storageTimeRules: {
            required: 'Data storage time is required'
        },
        dataFormatRules: {
            required: 'Data return format is required'
        },
        descriptionRules: {
            required: 'Contract description is required',
            specialCharacterRestriction: 'Contract description cannot contain special characters',
            maximumCharacterLimit: 'Contract description cannot exceed 300 characters'
        },
        newContract: 'New contract',
        contractEditor: 'Contract editor',
        contractId: 'Contract id',
        remainingTimes: 'Temaining times',
        remainingTime: 'Temaining time',
        checkBtn: 'Check',
        signContractBtn: 'Sign',
        signed: 'Signed',
        notSigned: 'Not signed',
        threeMonths: '3 months',
        sixMonths: '6 months',
        twelveMonths: '12 months',
    },
    purchaseHistory: {
        day: 'days',
        historyId: 'History id',
        renewalBtn: 'Renewal',
        stationCounts: 'Number of stations',
        serviceTime: 'Service time',
        dataFormat: 'Data Format',
        purchaseTime: 'Purchase time',
        paymentMethod: 'Payment method',
        paymentTime: 'Payment time',
        hours: 'hours',
        payBtn: 'Pay',
        newBtn: 'New Record',
        payTime: 'Payment time',
        paidBtn: 'Paid',
        paidDialogTitle: 'Set paid time',
        selectPaidTime: 'Select time',
        unpaidTip: 'You have an unpaid order, please pay as soon as possible'
    },
    user: {
        administrator: 'Administrator',
        studentCertification: 'Student certification',
        unbound: 'Unbound',
        notCertified: 'Not Certified',
        verified: 'Verified',
        refuseCertified: 'Deny authentication',
        checkingCertified: 'Awaiting authentication',
        passBtn: 'Pass',
        companyCertification: 'Company certification',
        newAdministrator: 'New administrator',
        email: 'E-mail',
        projectAdministrator: 'Project administrator',
        financialAdministrator: 'Financial administrator',
        resetPasswordBtn: 'Reset password',
        administratorEditor: 'Administrator editor',
        usernameRules: {
            required: 'Username is required',
            specialCharacterRestriction: 'Username cannot contain special characters',
            maximumCharacterLimit: 'Username cannot exceed 20 characters'
        },
        passwordRules: {
            required: 'Password is required',  
        },
        confirmPwdRules: {
            required: 'Confirm password is required',
            passwordConsistency: 'The two entered passwords do not match'  
        },
        emailRules: {
            required: 'E-mail is required',
            valid: 'E-mail must be valid'
        },
        editUser: 'Edit user',
        newUser: 'New user',
        generalUser: 'General user',
        apply: {
            certStatus: 'Authentication type',
            agreeBtn: 'Agree',
            rejectBtn: 'Reject'
        },
        userInfo: 'User Info',
        userApplication: 'User application'
    },
    setting: {
        certificationStatus: 'Certification status',
        studentIdPhoto: 'Student ID photo',
        basicInformation: 'Basic information',
        changePassword: 'Change password',
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        submitBtn: 'Submit',
        oldPasswordRules: {
            required: 'Old password is required',
        },
        newPasswordRules: {
            required: 'New password is required',
            minimumCharacterLimit: 'New password cannot be less than 16 characters',
            maximumCharacterLimit: 'New password cannot exceed 25 characters',
            specialCharacterRestriction: 'New password must contain both letters and numbers',   
        },
        fileLimit: 'File size exceeds 2M limit',
        photoRequired: 'Authentication photo required'
    },
    tips: {
        register: {
           success: 'Registration success',
           failed: 'Registration failed',            
        },
        login: {
            failed: 'Login failed'
        },
        operationSuccess: 'Operation success',
        operationFailed: 'Operation failed',
        userList: {
            delConfirm: 'Confirm deletion',
            delTips: 'It cannot be recovered after deletion, please operate with caution'
        },
        submitSuccess: 'Submit success',
        submitFailed: 'Submit failed',
        modifySuccess: 'Modify success',
        modifyFailed: 'Modify failed'
    },
    history: {
        startTime: 'Starting time',
        endTime: 'End time',
        exportBtn: 'export'
    }
}