<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
.v-btn {
  text-transform: none !important;
}
.v-tab {
  text-transform: none !important;
}
</style>
