export const lang = {
    register: {
        title: '注册账号',
        customerTypeLabel: '类型',
        naturalPerson: '自然人',
        company: '公司',
        businessLicense: '营业执照',
        usernameLabel: '用户名',
        passwordLabel: '密码',
        confirmPwdLabel: '再次输入',
        phoneNumberLabel: '手机号',
        verificationCodeLabel: '验证码',
        verificationCodeBtn: '获取验证码',
        resendverificationCode: '重新发送',
        registerBtn: '注册',
        toLoginBtn: '已有账号？去登录页',
        weiHaiWuZhou: '威海五洲',
        usernameRules: {
            required: '用户名必填',
            specialCharacterRestriction: '用户名不能包含特殊字符',
            minimumCharacterLimit: '用户名不能少于8个字符',
            maximumCharacterLimit: '用户名不能超过20个字符'
        },
        passwordRules: {
            required: '密码必填',
            minimumCharacterLimit: '密码不能少于16个字符',
            maximumCharacterLimit: '密码不能超过25个字符',
            specialCharacterRestriction: '密码必须同时包含字母和数字',   
        },
        confirmPwdRules: {
            required: '确认密码必填',
            minimumCharacterLimit: '确认密码不能少于16个字符',
            maximumCharacterLimit: '确认密码不能超过25个字符',
            specialCharacterRestriction: '确认密码必须同时包含字母和数字', 
            passwordConsistency: '两次输入的密码不一致'  
        },
        phoneNumberRules: {
            required: '手机号必填',
            formatValidation: '手机号格式错误',
        },
        verificationCodeRules: {
            required: '验证码必填'
        },
        sendSuccess: '发送成功',
        sendFail: '发送失败'
    },
    login: {
        platform: 'GNSS定位增强服务平台',
        title: '欢迎登录',
        loginBtn: '登录',
        toRegisterBtn: '没有账号?去注册',
        forgotPassword: '忘记密码',
        resetPassword: {
            title: '重设密码',
            phoneNumberVerification: '手机号验证',
            nextStep: '下一步',
            backToLogin: '返回登录页',
            enterPassword: '输入密码',
            finishBtn: '完成',
            resetSuccess: '重设成功',
            resetFail: '重设失败'
        }
    },
    home: {
        sideBar: {
           frontPage: '首页',
           stationManagement: '站管理',
           projectManagement: '项目管理',
           packageManagement: '套餐管理',
           pricingDisplay: '定价展示',
           purchaseHistory: '购买记录',
           contractManagement: '合同管理',
           userManagement: '用户管理',
           visualization: '可视化',
           chat: '聊天',
           dataDisplay: '数据展示',
           history: '历史数据',
           systemNotification: '系统通知',
           setUp: '设置',
           quickBuy: '快速购买'
        },
        description: '一款专门用来为企业和用户提供更精准定位的平台',
        settingBtn: '设置',
        signOutBtn: '登出',
        count: {
            totalUsers: '总用户数',
            totalProjects: '总项目数',
            totalStations: '总站数',
            onlineStations: '在线站数',
            totalContracts: '总合同数',
            signedContracts: '签约合同数'
        },
        stationLocation: '站点位置展示'
    },
    station: {
        stationNameRules: {
            required: '站名必填',
            specialCharacterRestriction: '站名不能包含特殊字符',
            maximumCharacterLimit: '站名不能超过20个字符'
        },
        latitudeRules: {
            required: '纬度必填',
            specialCharacterRestriction: '纬度不能包含特殊字符',
        },
        longitudeRules: {
            required: '经度必填',
            specialCharacterRestriction: '经度不能包含特殊字符',
        },
        heightRules: {
          required: '高度必填',
          specialCharacterRestriction: '高度不能包含特殊字符',
        },
        xRules: {
            required: 'x坐标必填',
            specialCharacterRestriction: 'x坐标不能包含特殊字符',
        },
        yRules: {
            required: 'y坐标必填',
            specialCharacterRestriction: 'y坐标不能包含特殊字符',
        },
        zRules: {
            required: 'z坐标必填',
            specialCharacterRestriction: 'z坐标不能包含特殊字符',
        },
        newStationBtn: '新建站',
        searchLabel: '搜索',
        stationId: '站点id',
        stationName: '站点名称',
        stationType: '站点类型',
        latitude: '纬度',
        longitude: '经度',
        height: '高度',
        x: 'x坐标',
        y: 'y坐标',
        z: 'z坐标',
        publicStation: '公共站',
        status: '状态',
        connectionKey: '连接密钥',
        creationTime: '创建时间',
        operation: '操作',
        editBtn: '编辑',
        deleteBtn: '删除',
        newStationTitle: '站点新建',
        stationEditorTitle: '站点编辑',
        referenceStation: '参考站',
        roverStation: '流动站',
        setAsPublicStation: '设为公共站',
        cancelBtn: '取消',
        saveBtn: '保存',
        online: '在线',
        offline: '离线',
        isPublicStation: '是',
        notPublicStation: '否',
        runningStatus: '运行中',
        isRunning: '是',
        notRunning: '否',
        stationIdRules: {
            required: '站点id必填',
            specialCharacterRestriction: '站点id不能包含特殊字符',
            maximumCharacterLimit: '站点id不能超过20个字符'
        },
        difference: '差分',
        lock: '锁',
        noSetRefStation: '该站已经在项目里，不能再设置参考站',
        setProject: '设置项目',
        noSetProject: '该站的参考站对应关系已经锁住，不能设置项目',
        deleteProject: '删除项目'

    },
    project: {
        projectNameRules: {
            required: '项目名必填',
            specialCharacterRestriction: '项目名不能包含特殊字符',
            maximumCharacterLimit: '项目名不能超过20个字符'
        },
        referenceStationRules: {
            required: '参考站必填',
            minCounts: '参考站个数不能少于两个'
        },
        roverStationRules: {
            required: '流动站必填'
        },
        descriptionRules: {
            required: '项目描述必填',
            specialCharacterRestriction: '项目描述不能包含特殊字符',
            maximumCharacterLimit: '项目描述不能超过300个字符'
        },
        newProjectBtn: '新建项目',
        projectId: '项目id',
        projectName: '项目名称',
        projectDescription: '项目描述',
        accessKey: '访问密钥',
        newProjectTitle: '项目新建',
        projectEditorTitle: '项目编辑',
        templateHints: '温馨提示： 按照模板创建项目时，差分定位只能选择一个参考站，CORS网可选择多个参考站，您也可以逃过模板自行创建。',
        skipTemplate: '跳过模板',
        diffPositioning: '差分定位',
        corsNet: 'CORS网',
        checkbox: '多选',
        radio: '单选',
        nextStep: '下一步',
        previousStep: '上一步',
        underProject: '该流动站在别的项目下',
        overwritten: '该流动站和参考站的对应关系将被覆盖',
        currentRoverList: '当前流动站列表',
        addRover: '添加流动站',
        resolving: '解算',
        resolvingList: '解算列表',
        start: '启动',
        stop: '停止'
    },
    package: {
        educationName: '教育版',
        educationDescription: '提供学生身份认证，享受不一样的优惠服务......',
        basicName: '基础版',
        basicDescription: '为政府/企业/用户提供定位增强服务',
        freeTrial: '免费体验',
        minute: '分钟',
        experienceService: '体验前所未有的高精度服务',
        payPerView: '按次收费',
        enjoyService: '享受全方位的服务',
        experienceAdvantages: '持续体验高精度定位的优势',
        times: '次',
        billedMonthly: '按月收费',
        monthlyDiscount: '包月更优惠',
        month: '月',
        premiumName: '高级版',
        customizedServices: '私人定制服务满足您多方面的需求',
        casesReference: '多种案例可供参考',
        operatorsHoping: '运营商希望共享电单车/网约车/共享汽车有明显优于常规基站辅助GPS定位的精度',
        privateCar: '私家车车载市场',
        farmEquipment: '农用设备市场',
        hopingOfSMEs: '中小企业希望用更低的成本测试自家的定位或导航服务',
        NonNavigation: '一些非导航专业研究人员希望简单地从事导航定位的研究',
        governmentHoping: '政府相关部门希望高精度监测桥梁和大坝',
        havingQustions: '如果您有任何问题',
        contact: '请联系',
        confirmBtn: '确认',
        priceRules: {
            required: '价格必填',
            specialCharacterRestriction: '价格不能包含特殊字符',
        },
        freeTry: '免费体验该套餐',
        completeStudentId: '请先去设置里完善您的学生证信息',
        months: '个月',
        numberOfStations: '可配置站的数量为',
        serviceTime: '可体验的服务时长为',
        dataDuration: '数据存储时长为',
        dataFormat: '数据返回格式为'

    },
    paymentStepper: {
        package: {
            title: '套餐',
            name: '套餐名称',
            type: '套餐类型',
            unitPrice: '单价',
            purchaseQuantity: '购买数量',
            totalAmount: '总金额',
        },
        paymentMethod: {
            title: '支付方式',
            hint: '请选择支付方式',
            onlinePayments: '在线支付',
            bankTransfer: '银行转账',
        },
        payment: '付款',
        finishBtn: '完成',
        contract: {
            title: '合同',
            partyA: '甲方',
            partyB: '乙方',
            totalAmount: '总金额',
            totalServices: '总服务次数',
            dataStorageTime: '数据存储时长',
            dataReturnFormat: '数据返回格式',
            contractDescription: '合同描述',
            agreeContract: '同意以上合同内容'
        },
        purchaseBtn: '购买'
    },
    quickBuy: {
        buyGuide: '购买指导',
        studentDiscount: '学生身份已经享受了最优惠的价格',
        stationNum: '站的数量',
        serviceTime: '服务时长',
        dataHoldTime: '数据存储时长',
        dataFormat: '数据返回格式',
        moreTime: '更多时长',
        oneMonth: '1个月',
        oneYear: '1年',
        threeYears: '3年',
        fiveYears: '5年',
        fourYears: '4年',
        twoYears: '2年',
        halfYear: '半年',
        threeMonths: '3个月',
        twoMonths: '2个月',
        fifteenDays: '15天',
        cost: '费用',
        calculatingPrice: '正在计算价格...',
        confirmBuy: '确认购买',
        buyTips: '确认购买内容以及付款金额',
        buyBtn: '购买',
        renewalBtn: '续费',
        tips: {
            buyFail: '购买失败',
            buySuccess: '购买成功'
        },
        transfer: {
            hint: '您已成功下单,请确认对方账户信息后再转账',
            bank: '开户行',
            bankName: '中国银行',
            account: '账户',
            companyName: '公司名',
            wuzhou: '威海五洲卫星导航科技股份有限公司',
            phone: '联系电话',
        },
        buyFailTip: {
            reason: '失败原因',
            unpaid: '存在未支付的订单',
            netError: '网络错误'
        }
    },
    contractWizard: {
        stationCountsRules: {
            required: '站的数量必填',
            specialCharacterRestriction: '站的数量不能包含特殊字符',
        },
        serviceTimesRules: {
            required: '总服务次数必填',
            specialCharacterRestriction: '总服务次数不能包含特殊字符',
        },
        serviceTimeRules: {
            required: '服务时长必填'
        },
        storageTimeRules: {
            required: '数据存储时长必填'
        },
        dataFormatRules: {
            required: '数据返回格式必填'
        },
        descriptionRules: {
            required: '合同描述必填',
            specialCharacterRestriction: '合同描述不能包含特殊字符',
            maximumCharacterLimit: '合同描述不能超过300个字符'
        },
        newContract: '新建合同',
        contractEditor: '编辑合同',
        contractId: '合同id',
        remainingTimes: '剩余次数',
        remainingTime: '剩余时长',
        checkBtn: '查看',
        signContractBtn: '签约',
        signed: '已签约',
        notSigned: '未签约',
        threeMonths: '3个月',
        sixMonths: '6个月',
        twelveMonths: '12个月',
    },
    purchaseHistory: {
        day: '天',
        historyId: '历史id',
        renewalBtn: '续费',
        stationCounts: '站的数量',
        serviceTime: '服务时长',
        dataFormat: '数据格式',
        purchaseTime: '购买时间',
        paymentMethod: '付款方式',
        paymentTime: '付款时间',
        hours: '小时',
        payBtn: '付款',
        newBtn: '新建记录',
        payTime: '支付时间',
        paidBtn: '已付',
        paidDialogTitle: '设置付款时间',
        selectPaidTime: '选择时间',
        unpaidTip: '您有未付款的订单，请尽快付款'
    },
    user: {
        administrator: '管理员',
        studentCertification: '学生认证',
        unbound: '未绑定',
        notCertified: '未认证',
        verified: '已认证',
        refuseCertified: '拒绝认证',
        checkingCertified: '等待认证',
        passBtn: '通过',
        companyCertification: '公司认证',
        newAdministrator: '新建管理员',
        email: '邮箱',
        projectAdministrator: '项目管理员',
        financialAdministrator: '财务管理员',
        resetPasswordBtn: '重置密码',
        administratorEditor: '编辑管理员',
        usernameRules: {
            required: '用户名必填',
            specialCharacterRestriction: '用户名不能包含特殊字符',
            maximumCharacterLimit: '用户名不能超过20个字符'
        },
        passwordRules: {
            required: '密码必填',  
        },
        confirmPwdRules: {
            required: '确认密码必填',
            passwordConsistency: '两次输入的密码不一致'  
        },
        emailRules: {
            required: '邮箱必填',
            valid: '邮箱必须有效'
        },
        editUser: '编辑用户',
        newUser: '新建用户',
        generalUser: '普通用户',
        apply: {
            certStatus: '认证类型',
            agreeBtn: '同意',
            rejectBtn: '拒绝'
        },
        userInfo: '用户信息',
        userApplication: '用户申请'
    },
    setting: {
        certificationStatus: '认证状态',
        studentIdPhoto: '学生证照片',
        basicInformation: '基础资料',
        changePassword: '修改密码',
        oldPassword: '原密码',
        newPassword: '新密码',
        confirmPassword: '确认密码',
        submitBtn: '提交',
        oldPasswordRules: {
            required: '原密码必填',
        },
        newPasswordRules: {
            required: '新密码必填',
            minimumCharacterLimit: '新密码不能少于16个字符',
            maximumCharacterLimit: '新密码不能超过25个字符',
            specialCharacterRestriction: '新密码必须同时包含字母和数字',   
        },
        fileLimit: '文件大小超过2M限制',
        photoRequired: '认证照片必填'
    },
    tips: {
        register: {
           success: '注册成功',
           failed: '注册失败',
        },
        login: {
            failed: '登录失败'
        },
        operationSuccess: '操作成功',
        operationFailed: '操作失败',
        userList: {
            delConfirm: '确认删除',
            delTips: '删除后不可恢复，请谨慎操作'
        },
        submitSuccess: '提交成功',
        submitFailed: '提交失败',
        modifySuccess: '修改成功',
        modifyFailed: '修改失败'
    },
    history: {
        startTime: '开始时间',
        endTime: '结束时间',
        exportBtn: '导出'
    }

}