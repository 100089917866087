<template>
  <v-app :class="$vuetify.breakpoint.lgAndUp ? 'app-padding app-background' : ''">
    <!-- md及已下 临时抽屉位于其应用程序上方 -->
    <v-navigation-drawer
      app
      width="340"
      :temporary="$vuetify.breakpoint.mdAndDown"
      v-model="showNavigationSideBar"
      :class="$vuetify.breakpoint.lgAndUp ? 'navigation-margin' : ''"
      class="overflow-rewrite"
    >
      <side-bar />
    </v-navigation-drawer>

    <v-main app class="main-background">
      <v-container fluid class="pa-0">
        <v-card elevation="0" tile>
          <v-card-title class="main-top-style">
            <!-- md及已下 显示按钮 -->
            <v-btn
              fab
              color="pink accent-2"
              class="mr-1"
              x-small
              @click="showNavigationSideBar = true"
              v-if="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <span class="subtitle-2">{{$t('lang.home.description')}}</span>
            <v-spacer></v-spacer>
            <!-- 语言切换 -->
            <v-menu left :offset-y="true" nudge-bottom="4">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text small v-bind="attrs" v-on="on">
                  <v-icon>mdi-translate</v-icon>
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>               
                <v-list class="px-1">
                  <v-list-item-group mandatory v-model="selectedLanguagesIndex">
                    <v-list-item v-for="(item, i) in languages" :key="i"  @click="switchLanguages(i)">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" :class="$vuetify.breakpoint.mdAndDown ? 'language-font' : ''"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>
            <!-- 账户信息 -->
            <v-menu left :offset-y="true" nudge-bottom="4">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text small v-bind="attrs" v-on="on">
                  <v-icon>mdi-account</v-icon>
                  <v-icon small>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-subtitle class="pt-1 pb-0">{{username}}</v-card-subtitle>
                <v-card-text class="pa-2">
                  <v-btn class="mr-2" color="primary" :small="$vuetify.breakpoint.mdAndDown" @click="toSetting()">{{$t('lang.home.settingBtn')}}</v-btn>
                  <v-btn color="error" :small="$vuetify.breakpoint.mdAndDown" @click="logout()">{{$t('lang.home.signOutBtn')}}</v-btn>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-card-title>
          <v-card-text class="main-background">
            <router-view></router-view>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SideBar from "../components/SideBar.vue";
import axios from 'axios'
import commonCfg from "../config/common";
let apiUrl = commonCfg.commonUrl
import qs from "qs";

export default {
  name: "Home",

  components: {
    SideBar,
  },
  data() {
    return {
      showNavigationSideBar: true,
      languages: [
        {text:'简体中文', abbr: 'zh-CN'}, 
        {text: 'English', abbr: 'en-US'}
      ],
      selectedLanguagesIndex: 0,
      username: localStorage.getItem("username")
    };
  },
  created() {
    //根据之前设置好的语言修改选中的语言下标
    if(!localStorage.getItem('lang')) return
    let index = this.languages.findIndex(item => item.abbr == localStorage.getItem('lang'))
    if(this.selectedLanguagesIndex != index) this.selectedLanguagesIndex = index
  },
  methods: {
    logout() {
      axios.post(`${apiUrl}logout`, qs.stringify({
        refresh_token: localStorage.getItem('refresh_token')
      })).then(res => {
        console.log(res)
        if(res.status == 204) { 
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          this.$router.push("/login");
        }
      }).catch(error => {
        console.log(error)
      })
    },
    toSetting() {
      this.$router.push("/setting")
    },
    //切换语言 把选中的语言保存下来
    switchLanguages(index) {
      //console.log(index, this.selectedLanguagesIndex)
      if(this.$i18n.locale == this.languages[index].abbr) return
      this.$i18n.locale = this.languages[index].abbr
      localStorage.setItem('lang', this.$i18n.locale)
      this.$router.go(0)
    }
  },
};
</script>
<style scoped>
* {
  --page-margin: 100px;
}
.main-top-style {
  /* background-color: blue; */
  border-color: rgb(228, 228, 228);
  border-width: 1px;
  border-style: solid;
  min-height: 77px;
}
.app-background {
  background-color: rgb(235, 237, 238) !important;
}
.main-background {
  background-color: rgb(245, 249, 255);
}
.app-padding {
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
}
.navigation-margin {
  margin-left: var(--page-margin);
}
::v-deep .overflow-rewrite .v-navigation-drawer__content {
  overflow-y: hidden;
}
.language-font {
  font-size: 12px;
}
</style>
