import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import axios from "axios";
import commonCfg from "../config/common";

Vue.use(VueRouter)

//Solve the problem: Avoided redundant navigation to current location
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '',
        name: '',
        component: () => import('../views/main.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/main',
        name: 'Main',
        component: () => import('../views/main.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/station',
        name: 'Station',
        component: () => import('../views/station.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/project',
        name: 'Project',
        component: () => import('../views/project.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/buy',
        name: 'Buy',
        component: () => import('../views/package/buy.vue'),
        meta: { requireAuth: true }
      },
      // {
      //   path: '/priceDisplay',
      //   name: 'Price',
      //   component: () => import('../views/package/price.vue'),
      //   meta: { requireAuth: true }
      // },
      {
        path: '/purchaseHistory',
        name: 'Purchase',
        component: () => import('../views/package/purchase.vue'),
        meta: { requireAuth: true }
      },
      // {
      //   path: '/contract',
      //   name: 'Contract',
      //   component: () => import('../views/contract.vue'),
      //   meta: { requireAuth: true }
      // },
      {
        path: '/setting',
        name: 'Setting',
        component: () => import('../views/setting.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/userList',
        name: 'UserList',
        component: () => import('../views/user/list.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/userApply',
        name: 'UserApply',
        component: () => import('../views/user/apply.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/chat',
        name: 'Chat',
        component: () => import('../views/chat.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/visualization',
        name: 'Visualization',
        component: () => import('../views/data/visualization.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/history',
        name: 'History',
        component: () => import('../views/data/history.vue'),
        meta: { requireAuth: true }
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/register.vue")
  },
  {
    path: '/first',
    name: 'First',
    component: () => import("../views/first.vue")
  }
]

const router = new VueRouter({
  //mode: 'history',
  //base: process.env.BASE_URL,
  routes
})

//全局有效 跳转之前执行
router.beforeEach(async (to, from, next) => {
  let vm = router.app;
  // 判断该路由是否需要登录权限
  if (to.meta.requireAuth) {
    if (localStorage.token) {
      //token失效 调用api会返回401 status code 在response拦截器里进行了拦截
      axios.get(`${commonCfg.commonUrl}me`).then(res => {
        console.log(res)
        if(res.status == 200){
          vm.$root.authority = res.data.authority //角色 ROLE_USER
          vm.$root.certType = res.data.certType //null,STUDENT,BUSINESS
          vm.$root.userId = res.data.id
          if(vm.$root.authority == 'ROLE_USER') vm.$root.userToken = res.data.token
          next();
        }
      }).catch(error => {
        console.log(error)
        router.push({ path: 'login' }).catch(() => true)
      })
    }else {
      router.push({ path: 'login' }).catch(() => true)
    }
  }else {
    next();
  }
});

export default router