import axios from "axios";
import router from "../router/index";

// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (localStorage.token) {
      if (
        config.url.indexOf("login") == -1 &&
        config.url.indexOf("sendSMSCode") == -1 &&
        config.url.indexOf("register") == -1 &&
        config.url.indexOf("api/v1") == -1//调用rocket chat相关api
      ) {
        config.headers.Authorization = `Bearer ${localStorage.token}`;
      }
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401: //token过期
          console.log('token过期')
          if (localStorage.token) {
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
          }
          router.replace({
            path: "/login" //跳转到登录页面
          });
          break;
        case 403:
          console.error('Permission denied');
          break;
        case 400:
          console.error('Bad Request')
          break;
      }
    }
    return Promise.reject(error.response.data);
  }
);
